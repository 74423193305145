<template>
    <div class=" mx-auto xl:container ">
        <div class="sm:my-10 xl:mx-10">
            <h1 class="dark:text-white text-gray-800 text-2xl">Экспорт данных клиента</h1>
            <Export  admin></Export>
            <ExportAllTransportations></ExportAllTransportations>
        </div>
    </div>
</template>

<script>

import Export from '@/components/common/Export'
import ExportAllTransportations from '@/components/user/admin/ExportAllTransportations'
export default {
    name: "ExportСlients",

    components:{
        Export, ExportAllTransportations
    }

}
</script>

<style scoped>

</style>