<template>
    <div class="export-trans__wrapper">
        <div
            class="export-trans__block">
            <h1 class="export-trans__title">Экспортировать все перевозки</h1>
            <p class="text-red-600 mb-5">Период не более 31 день</p>
            <div class="export-trans__main">
                <button :disabled="!isPeriodSelected" class="export-trans__action"
                        @click="transExport">
                    <i class="fas fa-file-excel"></i>
                </button>
                <DatePicker v-model="period" :range="true" :format="'DD.MM.YYYY'" ref="period-all-trans" class="export-trans__date"
                            lang="ru"
                            placeholder="Период"
                            :value-type="'format'"
                            :input-class="'dark:bg-gray-700  outline-none bg-white dark:text-white text-black  w-full '"
                            :popup-class="'dark:bg-gray-700'">

                </DatePicker>
            </div>
            <ProgressLinear class="my-10" v-show="loading"></ProgressLinear>

        </div>
    </div>

</template>

<script>
import axios from "axios";
import ProgressLinear from '@/components/common/ProgressLinear.vue'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from "moment";
import {writeXLSX} from "@/mixins/reports";

export default {
    name: "ExportAllTransportations",
    components: {
        ProgressLinear, DatePicker
    },
    data: () => ({
        loading: false,
        period: null,
        content: null,

    }),
    computed: {
        isPeriodSelected() {
            return this.period !== null && this.period.length === 2;
        }
    },
    methods: {
        isPeriodCorrect() {

            const [s, f] = this.period
            const start = moment(s, 'DD.MM.YYYY')
            const finish = moment(f, 'DD.MM.YYYY')
            const diff = start.diff(finish, 'days')
            if (Math.abs(diff) > 31) {
                this.$notify({
                    title: 'Внимание',
                    text: 'Период превышает 31 день.',
                    type: 'warn'
                })
                return false
            }
            return true
        },
        fetchData() {
            let link = 'https://declarant.by/rest/reports/transportations'
            let payload = {period: this.period}
            return axios.post(link, payload)
                .then(res => {
                    this.content = this.mapTransportations(res.data.data)
                })
                .catch(err => {
                    this.$notify({
                        title: 'Ошибка',
                        text: err.response.data.message,
                        type: 'error'
                    })
                })
        },
        mapTransportations(data) {
            const {blockname, headings} = data
            const items = []
            data.items.map(organization => {
                const {contract, orgName} = organization
                return organization.shipments.map(i => {
                    items.push({...i, contract, orgName})
                })
            })
            return {blockname, headings, items}
        },
        async transExport() {
            if (!this.isPeriodCorrect()) {
                return false
            }
            this.loading = true
            await this.fetchData()

            writeXLSX(this.content, {shipmentId: 'Грузоперевозки'})
            this.loading = false
        },
    }

}
</script>

<style scoped >
.export-trans__wrapper{
    @apply mr-5 sm:mb-5 lg:mb-0
}
.export-trans__block{
    @apply  my-10  dark:bg-gray-800 bg-white shadow-md  p-5 rounded
    sm:w-full
    lg:w-1/2
    xl:w-full
}
.export-trans__title{
    @apply dark:text-white text-gray-800 text-xl
}
.export-trans__main{
    @apply flex align-middle
}
.export-trans__action{
    @apply text-6xl dark:text-gray-200 text-gray-700 mr-5
}
.export-trans__date{
    @apply my-auto  text-black placeholder-gray-600  px-4 py-2.5 text-base dark:placeholder-gray-400
    transition duration-500 ease-in-out transform border-transparent
    bg-white dark:border-transparent border border-gray-200 rounded focus:border-gray-500 focus:bg-white dark:bg-gray-700
    dark:focus:bg-gray-800 focus:outline-none dark:text-gray-300
    dark:focus:text-white
}
</style>